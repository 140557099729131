<template>
  <div class="active-info">
    <div class="group">
      <van-cell-group style="margin-top: 12px;">
        <van-cell>
          <template #title>
            <div class="field-title">
              <span class="custom-title">新增人员</span>
            </div>
          </template>
        </van-cell>
        <van-field input-align="right" v-model="dataForm.name" label="姓名" placeholder="请输入" />
        <van-field input-align="right" v-model="dataForm.mobile" label="联系方式" placeholder="请输入" />
      </van-cell-group>
    </div>
    <div class="footer-btn">
      <van-button type="info" size="large" @click="getUserInfo" color="#387FF5" style="border-radius: 10px">确定新增
      </van-button>
      <!-- <van-button type="info" size="large" @click="submit" color="#387FF5" style="border-radius: 10px">确定</van-button> -->
    </div>
    <van-popup v-model="personShow" round position="bottom">
      <van-picker show-toolbar title="请确认新增人员" :columns="userList" value-key="label" @confirm="personChange"
        @cancel="personShow = false" />
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      id: '',
      dataForm: {
        name: '',
        mobile: '',
        userId: ''
      },
      userList: [],
      personShow: false
    }
  },
  mounted() {
    this.id = this.$route.query.id
  },
  computed: { ...mapState(['activityType']) },
  methods: {
    getUserInfo() {
      this.$http({
        url: this.$http.adornUrl(this.activityType == 1 ? '/wxapp/activity/sign/target' : '/wxapp/activity/sign/managerTarget'),
        method: 'post',
        params: this.$http.adornParams({
          name: this.dataForm.name,
          mobile: this.dataForm.mobile
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          if (data.list && data.list.length > 0) {
            this.userList = data.list.map(item => {
              return { ...item, label: item.name + '（' + item.mobile + '）', value: item.id }
            })
            this.personShow = true
          } else {
            this.$toast.fail({
              message: '用户不存在，请输入正确信息',
              duration: 1500,
            })
          }
        }
      })
    },
    submit() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/activity/sign/save'),
        method: 'post',
        data: this.$http.adornData({
          activityId: this.id,
          userId: this.dataForm.userId,
          communityId: this.$orgId,
          orgUserId: sessionStorage.getItem('orgUserId'),
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.$toast.success({
            message: '新增成功',
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$toast.clear()
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          this.$toast.clear()
          this.$toast.fail(data.msg)
        }
      })
    },
    personChange(val) {
      this.dataForm.userId = val.value
      this.dataForm.name = val.name
      this.dataForm.mobile = val.mobile
      this.personShow = false
      this.submit()
    }
  }
}
</script>

<style lang="scss" scoped>
.active-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  .footer-btn {
    font-size: 32px;
    font-family: PingFang-Bold;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    margin-bottom: 40px;

    .delelte-btn {
      width: 324px;
      height: 88px;
      border-radius: 20px;
      border: 2px solid #387FF5;
      color: #387FF5;
    }

    .edit-btn {
      width: 324px;
      height: 88px;
      background: #387FF5;
      border-radius: 20px;
      color: #FFFFFF;
    }
  }
}
</style>
